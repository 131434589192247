import Siema from 'siema';

const heroSliderContainer = document.querySelector('.js-heroSlider');

if (heroSliderContainer) {

    const homepageHeroNext = document.querySelector('.js-homepageHeroNext');
    const homepageHeroPrevious = document.querySelector('.js-homepageHeroPrevious');

    window.requestAnimationFrame(() => {

        const heroSlider = new Siema({
            selector: '.js-heroSlider',
            loop: true,
            easing: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
            duration: 1000
        });

        homepageHeroNext.addEventListener('click', () => {
            heroSlider.next(1);
        });

        homepageHeroPrevious.addEventListener('click', () => {
            heroSlider.prev(1);
        });
    }, 100);

}