import inView from 'in-view';

inView('.photoSample')
    .on('enter', el => {
        el.querySelector('.testimonialOverlay').classList.add('-revealed');
    });

inView('.testimonialFeature')
    .on('enter', el => {
        el.classList.add('-revealed');
    });

inView.offset(-100);