var modalTriggers = Array.from(document.querySelectorAll(".js-photoModal"));
var modal = document.querySelector(".js-photoModalContainer");
var modalPhoto = document.querySelector(".js-photoModalPhoto");

modalTriggers.forEach(modalTrigger => {
    modalTrigger.addEventListener('click', e => {

        let currentPhoto = modalTrigger.getAttribute("data-modal");

        modalPhoto.setAttribute("src", currentPhoto);
        modal.classList.add("-engaged");
        modal.classList.add("-opened");
    });
});

modal.addEventListener('click', () => {
    modal.classList.remove("-opened");
    modal.classList.remove("-engaged");
    modalPhoto.setAttribute("src", "");
});