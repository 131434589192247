export default (() => {

    var openMenu = document.querySelector('.js-mainMenuButton');
    var navigation = document.querySelector('.js-mainMenu');
    var closeMenu = document.querySelector('.js-menuClose');
    var subMenuTrigger = Array.from(document.querySelectorAll('.js-subMenuTrigger'));
    var subMenus = Array.from(document.querySelectorAll('.js-subMenu'));
    var subMenuClose = document.querySelector('.js-subMenuClose');


    openMenu.addEventListener('click', (e) => {
        navigation.classList.add('opened');
    });

    closeMenu.addEventListener('click', (e) => {
        navigation.classList.remove('opened');

        subMenus.forEach(subMenu => subMenu.classList.remove('opened'));
        subMenuClose.classList.remove('opened');
    });

    subMenuClose.addEventListener('click', e => {
        subMenus.forEach(subMenu => subMenu.classList.remove('opened'));

        subMenuClose.classList.remove('opened');
    });

    subMenuTrigger.forEach(menuLink => {
        menuLink.addEventListener("click", e => {
            if (window.innerWidth < 1030) {
                e.preventDefault();

                let dataMenu = menuLink.getAttribute('data-menu');
                let subMenu = document.querySelector('.js-subMenu[data-menu="' + dataMenu + '"]');

                subMenuClose.classList.add('opened');
                subMenu.classList.add('opened');

            }
        });
    });

})();