import axios from "axios";

const formSubmit = document.querySelector('.js-formSubmit');
const contactForm = document.querySelector('.js-contactForm');
const formFields = document.querySelector('.js-formFields');
const successMessage = document.querySelector('.js-successMessage');

if (formSubmit) {
    formSubmit.addEventListener('click',
        e => {
            e.preventDefault();
    
            const formData = new FormData(contactForm);
            axios.post('/', formData)
                .then(function (response) {
                    formFields.classList.add('-hide');
                    successMessage.classList.add('-revealed');
                })
                .catch(function (error) {
                    console.log(error);
                });;
        });
}